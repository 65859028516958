.div_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-X: hidden;
}

.card {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: #0006 2px 3px 10px;
    width: 99%;
    max-width: 99%;
    min-height: 50px;
    align-items: center;
}

.form_group {
    display: flex;
    align-items: center;
}

.form_group_label {
    margin-right: 5px;
    font-size: 1.3em;
}

.form_group_content select {
    font-size: 1.3em;
    border: none;
    background-color: #fff;
}

.form_group_content input {
    width: 100px;
    font-size: 1.3em;
    border: none;
    background-color: #fff;
}

.pizza_chart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 99%;
}