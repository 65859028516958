.rfo-container_modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: -210px;
    top: 0px;
    /* margin-left: -210px; */
    padding-right: -210px;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #00000055;
    z-index: 100
}


.rfo-close_btn_div {
    width: 100%;
    max-height: 60px;
    margin-left: 75%;
}

.rfo-content-modal {
    margin-left: 210px;
    width: auto;
    height: auto;
}

@media print {
    
    .close_btn_div {
        display: none;
    }
}