.notificationsIcon {
    cursor: pointer;
    margin-right: 15px;
}

.notificationsPopupNotifications {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
    position: absolute;
    right: 20px;
    min-height: 150px;
    margin-top: 40px;
    /* background-color: #3c68ce; */
    background-color: #fff;
    /* border: 1px solid #3760c0; */
    border: 1px solid #c3c3c355;
    padding: 20px;
    border-radius: 5px;
    color: #fff;
    z-index: 800;
    min-width: 50vw;
    max-width: 50vw;
    box-shadow: #00000055 5px 5px 10px;
}

.notificationsRow {
    cursor: pointer;
    display: flex;
    gap: 8px;
    flex-direction: row;
    color: #3760c0;
    border: 1px solid #c3c3c355;
    box-shadow: #0005 1px 1px 5px;
    border-radius: 10px;
    padding: 10px;
}


.notificationsRow div:nth-child(1) img {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
}

.notificationsContentModal {
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 60vw;
    height: 80vh;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.notificationsContentModalOriginalMessage {
    display: flex;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}


.notificationsContentModalComments {
    display: flex;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.CommentsFromMe {
    flex-direction: row-reverse;
}

.notificationUserNameInMsg{
    justify-self: flex-end;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    opacity: 0.5;
}

.notificationUserNameInMsg:hover{
    opacity: 1;
}
.notificationUserNameInMsg:focus{
    opacity: 1;
}

.notificationsContentModalOriginalMessage div:nth-child(1) {
    height: 100px;
}

.notificationsContentModalOriginalMessage div:nth-child(1) img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;

    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}

.notificationsContentModalOriginalMessage div:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    padding: 10px;
    height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
}

/* ************************** */

.notificationsContentModalComments div:nth-child(1) {
    height: 100px;
}

.notificationsContentModalComments div:nth-child(1) img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;

    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}

.notificationsContentModalComments div:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 10px;
    min-height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
}

.notificationsContentModalComments div:nth-child(2) p {
    
}



/* ************************** */


.notificationsTextbox {
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    height: 10vh;
    margin-top: 30px;
    justify-content: center;
    
}

.notificationsTextbox textarea {
    padding: 10px;
    border: 1px solid #0004;
    max-width: 91%;
    min-width: 91%;
    max-height: 12vh;
    min-height: 12vh;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #0006;
}

.notificationsTextbox div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 12px;
    margin-left: 2%;
    height: 8vh;
    border-radius: 4vh;
    border: 1px solid #0001;
    box-shadow: 2px 2px 10px #0006;
    width: 8vh;
    cursor: pointer;
}

.notificationsTextbox div span {
    /* transform: rotate(-30deg); */
    color: #fff;
    font-size: 4em;
}