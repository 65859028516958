.container_modal {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    background-color: #00000055;
    left: 0px;
    top: 0px;
    position: absolute;
    float: left;
    min-width: 100dvw;
    max-width: 100dvw;
    min-height: 100dvh;
    max-height: 100dvh;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    z-index: 100
}


.close_btn_div {
    width: 100%;
    max-height: 60px;
    margin-left: 75%;
}

@media print {
    .close_btn_div {
        display: none;
    }
}