.container-rfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    flex: 1;
}

.header-rfo {
    width: 100%;
}

.header-rfo img {
    width: 100%;
    object-fit: contain;
}

.content-rfo {
    display: flex;
    flex-direction: column;
    width: 90%;
    flex-grow: 1;
}

.content-rfo-title {
    align-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: bold;
}

.content-rfo-title2 {
    margin-top: 30px;
    font-size: 1.1em;
    font-weight: bold;
}

.content-rfo-txt {
    font-size: 1em;
}

.content-rfo-information{
    font-size: 1.1em;
}

.content-rfo table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    margin-bottom: 40px;
}

.content-rfo tr td:nth-child(1) {
    font-weight: bold;
    padding-left: 2px;
    width: 20%;
}

.content-rfo tr td:nth-child(2) {
    text-align: center;
}

.content-rfo td {
    font-size: 1.3em;
    padding: 0px;
    border: 1px solid #000;
}

.content-rfo ul {
    margin-left: 12px;
}

.content-rfo li {
    margin-top: 3px;
    margin-bottom: 12px;
}

.resposability_div {
    width: 100%;
    margin-top: 90px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
}

.resposability_div div {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.resposability_div div p:nth-child(1) {
    font-size: 1.2em;
    font-weight: bold;
}

.resposability_div div p:nth-child(2) {
    font-style: italic;
}

.footer-rfo {
    width: 100%;
}

.footer-rfo img {
    width: 100%;
    object-fit: contain;
}

.onlyprint{
    display: none;
}

.rfo-btnprint{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.rfo-display-linebreak{
    white-space: pre-line;
}

@media print {
    .header-rfo{
        display: none;
    }

    .footer-rfo{
        display: none;
    }

    .rfo-btnprint{
        display: none;
    }

    .container-rfo{
        position: absolute;
        background-color: #fff;
        top: 0px;
        left: 0px;
        min-width: 100;
        min-height: 100;
        overflow: hidden;
    }

    .onlyprint{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400;
        color: #000;
        z-index: 999;
    }

    .onlyprint h1 {
        font-size: 3em;
    }

    .content-rfo{
        display: none;
    }
}