.toast-list-users{ 
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 70vh;
    overflow-y: none;
    opacity: 1;
    background-color:  rgb(244, 244, 244);
}

.toast-list-users p{
    color: rgb(60, 92, 166);
    font-size: 1.3em;
    margin-bottom: 10px;
    font-weight: bold;
    text-shadow: #fff 2px 3px 10px;
}


.toast-list-users button{ 
    margin-top: 15px;
    border: none;
    width: 100%;
    background-color:  rgb(60, 92, 166);
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-weight: bold;
}

.toast-list-users-card{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(60, 92, 166);
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    color: #fff;
    font-weight: normal;
    cursor: pointer;
    opacity: 1;
}

.toast-list-users-card div{ 
    marginTop: 5px;
    marginBottom: 5px;
    fontSize: 1.1em;
    font-weight: bold;
}

.toast-list-users-card img{ 
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
    border: #c3c3c3 solid 2px 
}