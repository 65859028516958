
.outerContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #c3c3c3;
    /* border-radius: 16px; */
    /* background-color: antiquewhite; */
    position: relative;
    transition: background-color 0.5s;
}


.slider {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
    position: absolute;
    width: 90%;
}


.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    padding: 10px;
    border-radius: 3px;
    background: transparent;
    background-image: url("https://sgi.intecsolutions.com.br/forward.svg");
    background-color: #1d3879;
    /*Cor para substituição da imagem original */
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

/* .slider::-moz-range-thumb{

} */