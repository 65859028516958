:root {
  --background-sidebar: #3c5ca6;
  --highlight-mouse-hover-sidebar: #324d8c;
}


.inputWrapper{
  max-width: 140px;
  overflow: hidden;
}

.fileInput::-webkit-file-upload-button {
  visibility: hidden;
}

.fileInput::before {
  content: 'ANEXAR ARQUIVOS';
  display: inline-block;
  /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  background-color: var(--background-sidebar);
  border: none;
  border-radius: 5px;
  width: 140px;
  padding: 10px 8px;
  box-sizing: border-box;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  color: #fff;
  font-weight: bold;
  text-align: center;
  /* font-size: 10pt; */
}

.fileInput:hover::before {
  border-color: black;
}

.fileInput:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.buttom {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;

  /*    TOP  RIGHT  BOTTOM  LEFT */
  padding: 3px 8px 3px 8px;

  background-color: var(--background-sidebar);
  box-shadow: #0006 2px 2px 3px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer
}