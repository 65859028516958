.blink {
    border: none;
    -webkit-animation: fadeinout .5s linear infinite running alternate;
    animation: fadeinout .5s linear infinite running alternate;
    box-sizing: border-box;
}

.blinkdropshadow {
    -webkit-filter: drop-shadow(2px 2px 2px #fff0);
    filter: drop-shadow(2px 2px 2px #fff0);
    -webkit-animation: fadeinoutdropshadow .5s linear infinite running alternate;
    animation: fadeinoutdropshadow .5s linear infinite running alternate;
}

@-webkit-keyframes fadeinout {
  0% { border: 0px solid #fff0; }
  50% { border: 1px solid orange; }
  100% { border: 3px solid red; }
  /* 50% { border: 1px solid orange; } */
}

@keyframes fadeinout {
    0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; }
    /* 50% { border: 1px solid orange; } */
}

@-webkit-keyframes fadeinoutdropshadow {
    /* 0% { border: 0px solid #fff0; }
  50% { border: 1px solid orange; }
  100% { border: 3px solid red; } */

    0% {
        -webkit-filter: drop-shadow(2px 2px 4px #fff0 );
    }

    50% {
        -webkit-filter: drop-shadow(4px 4px 4px rgba(255, 0, 0, 0.5));
    }

    100% {
        -webkit-filter: drop-shadow(5px 5px 4px rgba(255, 0, 0, 1));
    }
}

@keyframes fadeinoutdropshadow {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        filter: drop-shadow(2px 2px 4px #fff0);
    }

    50% {
        filter: drop-shadow(4px 4px 4px rgba(255, 0, 0, 0.5));
    }

    100% {
        filter: drop-shadow(5px 5px 4px rgba(255, 0, 0, 1));
    }
}