.notificationsIcon {
    cursor: pointer;
    margin-right: 15px;
}

/* .notificationsPopupNotifications {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
    position: absolute;
    right: 20px;
    min-height: 150px;
    margin-top: 40px;
    
    background-color: #fff;
    
    border: 1px solid #c3c3c355;
    padding: 20px;
    border-radius: 5px;
    color: #fff;
    z-index: 800;
    min-width: 50vw;
    max-width: 50vw;
    box-shadow: #00000055 5px 5px 10px;
} */

.notificationsContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 15px;
    height: auto;
    overflow-y: auto;
}

.notificationsRow {
    /* cursor: pointer; */
    display: flex;
    gap: 8px;
    /* flex-direction: row; */
    flex-direction: column;
    color: #3760c0;
    border: 1px solid #c3c3c355;
    box-shadow: 2px 2px 5px #0005;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    
    overflow: hidden;
    transition: max-height 1s;
}

.notificationsRowAvatar{
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    border: 3px solid #3c68ce99;
}

.notificationsContentModal {
    display: flex;
    gap: 15px;
    flex-direction: column;
    width: 60vw;
    height: 80vh;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.notificationsContentModalOriginalMessage {
    display: flex;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}


.notificationsContentModalComments {
    display: flex;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.CommentsFromMe {
    flex-direction: row-reverse;
}

.notificationUserNameInMsg{
    justify-self: flex-end;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    opacity: 0.5;
}

.notificationUserNameInMsg:hover{
    opacity: 1;
}
.notificationUserNameInMsg:focus{
    opacity: 1;
}

/* .notificationsContentModalOriginalMessage div:nth-child(1) {
    height: 100px;
} */

/* .notificationsContentModalOriginalMessage div:nth-child(1) img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;

    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
} */

/* .notificationsContentModalOriginalMessage div:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    padding: 10px;
    height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
} */

/* ************************** */

.notificationCommentAvatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;

    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}

.notificationCommentContent {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 10px;
    min-height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
}


.notificationsBtnNewPost{
    padding: 10px;
    display: flex;
    position: absolute;
    bottom: 30px;
    right: 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #0006;
    cursor: pointer;
}

.notificationsBtnNewPost span{
    margin: 0px;
}

/* ************************** */


.notificationsTextbox {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 20vh;
    /* margin-top: 30px; */
    /* transition: margin-top 1s; */
    justify-content: center;
}

.notificationsTextarea {
    padding: 10px;
    border: 1px solid #0004;
    width: 91%;
    /* min-width: 91%; */
    max-height: 8vh;
    min-height: 8vh;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #0006;
    resize: none;
}

.notificationsBtnSendComment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-left: 2%;
    height: 4vh;
    width: 4vh;
    border-radius: 4vh;
    border: 1px solid #0001;
    box-shadow: 2px 2px 10px #0006;
    cursor: pointer;
}

.notificationsBtnSendComment span {
    /* transform: rotate(-30deg); */
    margin: 0px;
    color: #fff;
    font-size: 3em;
}