@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
}

:root {
  --primary-color: #3c5ca6;
  --highlight-mouse-hover-sidebar: #324d8c;

  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}


html,
body {
  width: 100dvw;
  max-width: 100dvw;
  color: var(--primary-color);
  overflow: hidden;
}

pre {
  white-space: pre-wrap;
  /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.flex {
  display: flex;
}

.fullsize {
  flex-grow: 1;
}

.flexWrap {
  flex-wrap: wrap;
}

.flexCol {
  flex-direction: column;
}

.flexRow {
  flex-direction: row;
}

.center-v {
  align-items: center;
}

.center-h {
  justify-content: center;
}

.justify-content-sa {
  justify-content: space-around;
}

.justify-content-sb {
  justify-content: space-between;
}

.justify-content-se {
  justify-content: space-evenly;
}

.justify-content-c {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.card {
  margin: 10px;
  min-width: 275;
  box-sizing: border-box;
}

.fontRoboto {
  font-family: "Roboto", sans-serif;
}

.accordion {
  max-height: 0px;
  /*define height to start from*/
  transition: max-height 0.5s;
  -moz-transition: max-height 0.5s;
  /* Firefox 4 */
  -webkit-transition: max-height 0.5s;
  /* Safari and Chrome */
  -o-transition: max-height 0.5s;
  /* Opera */
  overflow: hidden;
}

.accordion.expanded {
  max-height: 1500px
    /*desired height when expanded*/
}

a:link {
  text-decoration: none;
}

a {
  color: inherit;
}

.borda_acima_2_filho:nth-child(n + 3) {
  border-top: 1px #fff solid;
  padding-top: 10px;
}


.sliderAnimation {
  overflow-y: hidden;
  max-height: 500px;
  /* approximate max height */

  transition-property: all;
  transition-duration: 5s;
  transition-timing-function: cubic-bezier(0, 1, 2, 1);
}

.sliderIn {
  position: absolute;
  bottom: 500;
}

.sliderOut {
  position: absolute;
  bottom: -500;
}


/* ******************************** */


/* **************************** */


@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }
}

@keyframes moveUP {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(300px);
  }
}

@keyframes moveUP {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(300px);
  }
}

@keyframes moveDOWN {
  0% {
    transform: translateY(300px);
  }

  100% {
    transform: translateY(0px);
  }
}

.animation_filter_active {
  animation: zoominoutsinglefeatured 3s infinite;
}



.loader {
  border: px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #3c5ca6;
  /* Safari */
  animation: spin 2s linear infinite;
}

.loader2 {
  /* border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px; */
  animation: spin 2s linear infinite;
}

.miniloader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3c5ca6;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



@keyframes menu-open {
  from {
    margin-left: -210px;
  }

  to {
    margin-left: 0px;
  }
}

.menu_open {
  animation-name: menu-open;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes menu-close {
  from {
    margin-left: 0px;
  }

  to {
    margin-left: -210px;
  }
}

.menu_close {
  animation-name: menu-close;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}


.header__menu {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  outline: 1px solid var(--border-accent);
  padding: 64px 12px 0 12px;
  height: 100vh;
  z-index: 9;
}

.ticketsTableMin {
  display: none;
}


.modal_40_70 {
  width: 40%;
  width: 40%;
}

.modal_40_95 {
  min-width: 50%;
  max-width: 50%;
}

.modal_50_80 {
  min-width: 50%;
  max-width: 50%;
}

.modal_50_95 {
  min-width: 50%;
  max-width: 50%;
}

.height_less_header {
  max-height: calc(100dvh - 50px);
  min-height: calc(100dvh - 50px);
}

.container_logo_header {
  margin-left: 50px
}


.container_cards_contadores {
  max-width: 94%;
  min-width: 94%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.font3em {
  font-size: 3em;
}


.select_style {
  /* A reset of styles, including removing the default dropdown arrow */
  /* appearance: none; */
  /* Additional resets for further consistency */
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}


.div_divider_h_2_percent {
  min-width: 2%;
}

.card_contador_chamados {
  color: '#111';
  margin: 10px;
  min-height: 100px;
  max-height: 100px;
  min-width: 200px;
  max-width: 200px;
  cursor: 'pointer'
}


.hover-opacity:hover {
  cursor: pointer;
  opacity: 0.8;
}

.hover-transform:hover {
  cursor: pointer;
  transform: scale(1.01);
}

.hover-opacity:active {
  cursor: pointer;
  opacity: 0.9;
}

.hover-transform:active {
  cursor: pointer;
  opacity: 0.9;
}



@media print {
  .noprint {
    visibility: hidden;
  }
}

@media only screen and (max-width: 1220px) {
  * {
    font-size: 10;
  }

  .div_divider_h_2_percent {
    min-width: 0px;
  }

  .card_contador_chamados {
    color: '#111';
    margin: 10px;
    min-height: 100px;
    max-height: 100px;
    min-width: 180px;
    max-width: 180px;
    cursor: 'pointer'
  }

  div[class*="menu_open"] {
    animation-name: menu-close;
  }

  div[class*="menu_close"] {
    animation-name: menu-open;
  }

  .container_cards_contadores {
    max-width: 100%;
    min-width: 100%;
    /* display: flex; 
    justify-content: space-between;
    flex-wrap: wrap */
  }

  .ticketsTableFull {
    display: none;
  }

  .ticketsTableMin {
    display: block;
  }

  .modal_40_70 {
    min-width: 70%;
    max-width: 70%;
  }

  .modal_40_95 {
    min-width: 95%;
    max-width: 95%;
  }

  .modal_50_80 {
    min-width: 80%;
    max-width: 80%;
  }

  .modal_50_95 {
    min-width: 95%;
    max-width: 95%;
  }

  .container_logo_header {
    margin-left: 5px
  }
}
:root{
  --background-sidebar: #3c5ca6;
  --highlight-mouse-hover-sidebar: #324d8c;
}


.navbar {
  background-color: var(--background-sidebar);
  height: 50px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;
  z-index: 9000;
  border-bottom: 1px #324d8c solid;
}

.navbar a {padding-left: 20px;}

.menu-bars {
  margin-left: 2rem;
  font-size: 1.5rem;
  background: none;

}

.nav-menu {
  background-color: var(--background-sidebar);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 9000;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  z-index: 9999;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: var(--highlight-mouse-hover-sidebar)
}

.nav-menu-items {
  margin-top: 100px;
  width: 100%;
  z-index: 9999;
}

.navbar-toggle {
  background-color: var(--background-sidebar);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}


.classes-nesting-root{
  color: #fff !important;
}
.container_modal {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1;
    background-color: #00000055;
    left: 0px;
    top: 0px;
    position: absolute;
    float: left;
    min-width: 100dvw;
    max-width: 100dvw;
    min-height: 100dvh;
    max-height: 100dvh;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    z-index: 100
}


.close_btn_div {
    width: 100%;
    max-height: 60px;
    margin-left: 75%;
}

@media print {
    .close_btn_div {
        display: none;
    }
}
.toast-list-users{ 
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 70vh;
    overflow-y: none;
    opacity: 1;
    background-color:  rgb(244, 244, 244);
}

.toast-list-users p{
    color: rgb(60, 92, 166);
    font-size: 1.3em;
    margin-bottom: 10px;
    font-weight: bold;
    text-shadow: #fff 2px 3px 10px;
}


.toast-list-users button{ 
    margin-top: 15px;
    border: none;
    width: 100%;
    background-color:  rgb(60, 92, 166);
    border-radius: 5px;
    padding: 10px;
    color: #fff;
    font-weight: bold;
}

.toast-list-users-card{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(60, 92, 166);
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    color: #fff;
    font-weight: normal;
    cursor: pointer;
    opacity: 1;
}

.toast-list-users-card div{ 
    marginTop: 5px;
    marginBottom: 5px;
    fontSize: 1.1em;
    font-weight: bold;
}

.toast-list-users-card img{ 
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 25px;
    border: #c3c3c3 solid 2px 
}
.styles_lineTimes__3e-61{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.styles_lineTimes__3e-61 div:nth-child(1){
    width: 50px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.styles_lineTimes__3e-61 div:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
}

.styles_lineTimes__3e-61 input{
    width: 160px;
}

.styles_transitionHeight__2nW13{
    transition: height 0.5s;
}

.swipeToConfirm_outerContainer__2ZAnT {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #c3c3c3;
    /* border-radius: 16px; */
    /* background-color: antiquewhite; */
    position: relative;
    transition: background-color 0.5s;
}


.swipeToConfirm_slider__1FXsf {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
    position: absolute;
    width: 90%;
}


.swipeToConfirm_slider__1FXsf::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    padding: 10px;
    border-radius: 3px;
    background: transparent;
    background-image: url("https://sgi.intecsolutions.com.br/forward.svg");
    background-color: #1d3879;
    /*Cor para substituição da imagem original */
    background-size: 100%;
    background-repeat: no-repeat;
    cursor: pointer;
}

/* .slider::-moz-range-thumb{

} */
:root {
    --background-sidebar: #3c5ca6;
    --highlight-mouse-hover-sidebar: #324d8c;
}

.default_color {
    color: var(--background-sidebar);
    background-color: var(--background-sidebar);
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* max-width: calc(100vw - 210px);
    max-height: calc(100vh - 50px); */
    overflow-y: auto;
    overflow-X: hidden;
}

.width_content {
    width: calc(100vw - 300px);
    max-width: calc(100vw - 300px);
}


.fixToCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}


.flex_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.flex_column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.card {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: #0006 2px 3px 10px;
    width: 99%;
    max-width: 99%;
    min-height: 50px;
    align-items: center;
}

.buttom {
    display: flex;
    flex-direction: row;
    align-items: center;

    /*    TOP  RIGHT  BOTTOM  LEFT */
    padding: 3px 8px 3px 8px;

    background-color: var(--background-sidebar);
    box-shadow: #0006 2px 2px 3px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer
}

.buttom:hover {
    background-color: var(--highlight-mouse-hover-sidebar)
}


.left_icon {
    margin-left: -3px;
    padding-top: 0px;
    margin-right: 5px;
}

.form {
    width: 50%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}


.boxToHiddenEffect {
    height: auto;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.hidden {
    height: 0px;
    transition: height 0.5s linear;
}

.hidden.open {
    height: 200px;
    transition: height 0.5s linear;
}




.hidden_out {
    overflow: hidden;
    margin-left: -200%;
    transition: margin-left 0.5s linear;
}

.show_in {
    margin-left: 20px;
    transition: margin-left 0.5s linear;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.load {
    /* width: 100px;
	height: 100px;
	margin: 110px auto 0; */
    border: solid 2px #3c5ca6;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


.blink {
    border: none;
    animation: fadeinout .5s linear infinite running alternate;
}

@keyframes fadeinout {
    0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; }
    /* 50% { border: 1px solid orange; } */
}
:root {
    --background-sidebar: #3c5ca6;
    --highlight-mouse-hover-sidebar: #324d8c;
}

.default_color {
    color: var(--background-sidebar);
    background-color: var(--background-sidebar);
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* max-width: calc(100vw - 210px);
    max-height: calc(100vh - 50px); */
    overflow-y: auto;
    overflow-X: hidden;
}

.outage-rows-container{
    display:flex;
    grid-gap: 10;
    gap: 10;
    width: 100%;
    padding-bottom: 13;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 50px - 170px)
}

.outage-report-rows-container{
    display:flex;
    grid-gap: 10;
    gap: 10;
    width: 100%;
    padding-bottom: 13;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 50px - 310px)
}

.outage-details{ 
    width: 100%; 
    flex-direction: row;
    font-size: 1.3em
}

.outage-details span{ 
    margin-left: 0px;
    margin-bottom: 5px; 
    width: 50%;
    font-size: 1.2em;
}
.outage-details p{ 
    margin-top: 10px; 
    font-size: 1.2em;
}

.outage-details p{
    font-size: 1em;
}

.outage-details-interactions{ 
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between 
}

.outage-details-interactions p:nth-child(2){
    margin-top: 10px
}

.outage-details-interactions hr{
    margin-top: 10px;
    margin-bottom: 10px
}

.width_content {
    width: calc(100vw - 300px);
    max-width: calc(100vw - 300px);
}


.fixToCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}


.flex_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.flex_column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.card {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: #0006 2px 3px 10px;
    width: 99%;
    max-width: 99%;
    min-height: 50px;
    align-items: center;
}

.buttom {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;

    /*    TOP  RIGHT  BOTTOM  LEFT */
    padding: 3px 8px 3px 8px;

    background-color: var(--background-sidebar);
    box-shadow: #0006 2px 2px 3px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer
}

.buttom-disabled {
    display: flex;
    flex-direction: row;
    align-items: center;

    /*    TOP  RIGHT  BOTTOM  LEFT */
    padding: 3px 8px 3px 8px;

    background-color: #c3c3c3;
    box-shadow: #0006 2px 2px 3px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
}

.buttom:hover {
    background-color: var(--highlight-mouse-hover-sidebar)
}


.left_icon {
    margin-left: -3px;
    padding-top: 0px;
    margin-right: 5px;
}

.form {
    width: 50%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}


.boxToHiddenEffect {
    height: auto;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.hidden {
    height: 0px;
    transition: height 0.5s linear;
}

.hidden.open {
    height: 200px;
    transition: height 0.5s linear;
}




.hidden_out {
    overflow: hidden;
    margin-left: -200%;
    transition: margin-left 0.5s linear;
}

.show_in {
    margin-left: 20px;
    transition: margin-left 0.5s linear;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.load {
    /* width: 100px;
	height: 100px;
	margin: 110px auto 0; */
    border: solid 2px #3c5ca6;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


.blink {
    border: none;
    animation: fadeinout .5s linear infinite running alternate;
}

@keyframes fadeinout {
    0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; }
    /* 50% { border: 1px solid orange; } */
}
.rfo-container_modal {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: -210px;
    top: 0px;
    /* margin-left: -210px; */
    padding-right: -210px;
    min-width: 100vw;
    min-height: 100vh;
    background-color: #00000055;
    z-index: 100
}


.rfo-close_btn_div {
    width: 100%;
    max-height: 60px;
    margin-left: 75%;
}

.rfo-content-modal {
    margin-left: 210px;
    width: auto;
    height: auto;
}

@media print {
    
    .close_btn_div {
        display: none;
    }
}
:root {
  --background-sidebar: #3c5ca6;
  --highlight-mouse-hover-sidebar: #324d8c;
}


.UploadComponentStyle_inputWrapper__CsSem{
  max-width: 140px;
  overflow: hidden;
}

.UploadComponentStyle_fileInput__1caQZ::-webkit-file-upload-button {
  visibility: hidden;
}

.UploadComponentStyle_fileInput__1caQZ::before {
  content: 'ANEXAR ARQUIVOS';
  display: inline-block;
  /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  background-color: var(--background-sidebar);
  border: none;
  border-radius: 5px;
  width: 140px;
  padding: 10px 8px;
  box-sizing: border-box;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  color: #fff;
  font-weight: bold;
  text-align: center;
  /* font-size: 10pt; */
}

.UploadComponentStyle_fileInput__1caQZ:hover::before {
  border-color: black;
}

.UploadComponentStyle_fileInput__1caQZ:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.UploadComponentStyle_buttom__13bID {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;

  /*    TOP  RIGHT  BOTTOM  LEFT */
  padding: 3px 8px 3px 8px;

  background-color: var(--background-sidebar);
  box-shadow: #0006 2px 2px 3px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer
}
.container-rfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    flex: 1 1;
}

.header-rfo {
    width: 100%;
}

.header-rfo img {
    width: 100%;
    object-fit: contain;
}

.content-rfo {
    display: flex;
    flex-direction: column;
    width: 90%;
    flex-grow: 1;
}

.content-rfo-title {
    align-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 1.5em;
    font-weight: bold;
}

.content-rfo-title2 {
    margin-top: 30px;
    font-size: 1.1em;
    font-weight: bold;
}

.content-rfo-txt {
    font-size: 1em;
}

.content-rfo-information{
    font-size: 1.1em;
}

.content-rfo table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    margin-bottom: 40px;
}

.content-rfo tr td:nth-child(1) {
    font-weight: bold;
    padding-left: 2px;
    width: 20%;
}

.content-rfo tr td:nth-child(2) {
    text-align: center;
}

.content-rfo td {
    font-size: 1.3em;
    padding: 0px;
    border: 1px solid #000;
}

.content-rfo ul {
    margin-left: 12px;
}

.content-rfo li {
    margin-top: 3px;
    margin-bottom: 12px;
}

.resposability_div {
    width: 100%;
    margin-top: 90px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
}

.resposability_div div {
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.resposability_div div p:nth-child(1) {
    font-size: 1.2em;
    font-weight: bold;
}

.resposability_div div p:nth-child(2) {
    font-style: italic;
}

.footer-rfo {
    width: 100%;
}

.footer-rfo img {
    width: 100%;
    object-fit: contain;
}

.onlyprint{
    display: none;
}

.rfo-btnprint{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.rfo-display-linebreak{
    white-space: pre-line;
}

@media print {
    .header-rfo{
        display: none;
    }

    .footer-rfo{
        display: none;
    }

    .rfo-btnprint{
        display: none;
    }

    .container-rfo{
        position: absolute;
        background-color: #fff;
        top: 0px;
        left: 0px;
        min-width: 100;
        min-height: 100;
        overflow: hidden;
    }

    .onlyprint{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 400;
        color: #000;
        z-index: 999;
    }

    .onlyprint h1 {
        font-size: 3em;
    }

    .content-rfo{
        display: none;
    }
}
.style_blink__aj0JC {
    border: none;
    animation: style_fadeinout__1rRRF .5s linear infinite running alternate;
    box-sizing: border-box;
}

.style_blinkdropshadow__E8f8r {
    filter: drop-shadow(2px 2px 2px #fff0);
    animation: style_fadeinoutdropshadow__2ZWWk .5s linear infinite running alternate;
}

@keyframes style_fadeinout__1rRRF {
    0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; }
    /* 50% { border: 1px solid orange; } */
}

@keyframes style_fadeinoutdropshadow__2ZWWk {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        filter: drop-shadow(2px 2px 4px #fff0);
    }

    50% {
        filter: drop-shadow(4px 4px 4px rgba(255, 0, 0, 0.5));
    }

    100% {
        filter: drop-shadow(5px 5px 4px rgba(255, 0, 0, 1));
    }
}
.styles_notificationsIcon__3NM-m {
    cursor: pointer;
    margin-right: 15px;
}

.styles_notificationsPopupNotifications__1mrKB {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    overflow: auto;
    position: absolute;
    right: 20px;
    min-height: 150px;
    margin-top: 40px;
    /* background-color: #3c68ce; */
    background-color: #fff;
    /* border: 1px solid #3760c0; */
    border: 1px solid #c3c3c355;
    padding: 20px;
    border-radius: 5px;
    color: #fff;
    z-index: 800;
    min-width: 50vw;
    max-width: 50vw;
    box-shadow: #00000055 5px 5px 10px;
}

.styles_notificationsRow__2o4SL {
    cursor: pointer;
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    flex-direction: row;
    color: #3760c0;
    border: 1px solid #c3c3c355;
    box-shadow: #0005 1px 1px 5px;
    border-radius: 10px;
    padding: 10px;
}


.styles_notificationsRow__2o4SL div:nth-child(1) img {
    min-width: 50px;
    max-width: 50px;
    min-height: 50px;
    max-height: 50px;
}

.styles_notificationsContentModal__3r_xp {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
    width: 60vw;
    height: 80vh;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.styles_notificationsContentModalOriginalMessage__PY2qe {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}


.styles_notificationsContentModalComments__3NHI2 {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.styles_CommentsFromMe__1y-kb {
    flex-direction: row-reverse;
}

.styles_notificationUserNameInMsg__3b_cI{
    justify-self: flex-end;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    opacity: 0.5;
}

.styles_notificationUserNameInMsg__3b_cI:hover{
    opacity: 1;
}
.styles_notificationUserNameInMsg__3b_cI:focus{
    opacity: 1;
}

.styles_notificationsContentModalOriginalMessage__PY2qe div:nth-child(1) {
    height: 100px;
}

.styles_notificationsContentModalOriginalMessage__PY2qe div:nth-child(1) img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;
    filter: drop-shadow(5px 5px 5px #222);
}

.styles_notificationsContentModalOriginalMessage__PY2qe div:nth-child(2) {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;

    padding: 10px;
    height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
}

/* ************************** */

.styles_notificationsContentModalComments__3NHI2 div:nth-child(1) {
    height: 100px;
}

.styles_notificationsContentModalComments__3NHI2 div:nth-child(1) img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;
    filter: drop-shadow(5px 5px 5px #222);
}

.styles_notificationsContentModalComments__3NHI2 div:nth-child(2) {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 10px;
    min-height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
}

.styles_notificationsContentModalComments__3NHI2 div:nth-child(2) p {
    
}



/* ************************** */


.styles_notificationsTextbox__r0-eN {
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 95%;
    height: 10vh;
    margin-top: 30px;
    justify-content: center;
    
}

.styles_notificationsTextbox__r0-eN textarea {
    padding: 10px;
    border: 1px solid #0004;
    max-width: 91%;
    min-width: 91%;
    max-height: 12vh;
    min-height: 12vh;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #0006;
}

.styles_notificationsTextbox__r0-eN div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 12px;
    margin-left: 2%;
    height: 8vh;
    border-radius: 4vh;
    border: 1px solid #0001;
    box-shadow: 2px 2px 10px #0006;
    width: 8vh;
    cursor: pointer;
}

.styles_notificationsTextbox__r0-eN div span {
    /* transform: rotate(-30deg); */
    color: #fff;
    font-size: 4em;
}
:root {
    --background-sidebar: #3c5ca6;
    --highlight-mouse-hover-sidebar: #324d8c;
}

.default_color {
    color: var(--background-sidebar);
    background-color: var(--background-sidebar);
}

.alerta {
    background-color: rgb(255, 171, 61);
    color: #253a6a;
}

.critico {
    background-color: rgb(255, 61, 61);
    color: #fff
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* max-width: calc(100dvw - 210px); */
    /* max-height: calc(100vh - 50px); */
    overflow-y: auto;
    overflow-X: hidden;

}

.outage-rows-container {
    display: flex;
    grid-gap: 10;
    gap: 10;
    width: 100%;
    padding-bottom: 13;
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100vh - 50px - 170px)
}

.outage-details {
    display: flex;
    width: 100%;
    flex-direction: row;
    font-size: 1.3em;
}

.outage-details span {
    margin-left: 0px;
    margin-bottom: 5px;
    width: 50%;
    font-size: 1.2em;
}

.outage-details p {
    margin-top: 10px;
    font-size: 1.2em;
}

.outage-details p {
    font-size: 1em;
}

.outage-details b {
    font-size: 1em;
}

.outage-details-interactions {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.outage-details-interactions p:nth-child(2) {
    margin-top: 10px
}

.outage-details-interactions hr {
    margin-top: 10px;
    margin-bottom: 10px
}

.outage-rows-container {
    display: flex;
    grid-gap: 10;
    gap: 10;
    width: 100%;
    padding-bottom: 13;
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100vh - 50px - 170px)
}

.row-outage-title {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    grid-gap: 10;
    gap: 10;
    align-self: center;
    font-size: 1.8em
}

.row-outage-title span {
    font-size: 1.3em
}

.row-outage-data {
    position: relative;
    display: flex;
    /* flex-direction: row; */
    width: 100%;
    flex-direction: column
}

.row-outage-data span {
    font-size: 1.4em;
}

.width_content {
    width: calc(100vw - 300px);
    max-width: calc(100vw - 300px);
}


.fixToCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}


.flex_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.flex_column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.card {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: #0006 2px 3px 10px;
    width: 99%;
    max-width: 99%;
    align-items: center;
}

.buttom {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;

    /*    TOP  RIGHT  BOTTOM  LEFT */
    padding: 3px 8px 3px 8px;

    background-color: var(--background-sidebar);
    box-shadow: #0006 2px 2px 3px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer
}

.buttom-disabled {
    display: flex;
    flex-direction: row;
    align-items: center;

    /*    TOP  RIGHT  BOTTOM  LEFT */
    padding: 3px 8px 3px 8px;

    background-color: #c3c3c3;
    box-shadow: #0006 2px 2px 3px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
}

.buttom:hover {
    background-color: var(--highlight-mouse-hover-sidebar)
}


.left_icon {
    margin-left: -3px;
    padding-top: 0px;
    margin-right: 5px;
}

.form {
    width: 50%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}


.boxToHiddenEffect {
    height: auto;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.hidden {
    height: 0px;
    transition: height 0.5s linear;
}

.hidden.open {
    height: 200px;
    transition: height 0.5s linear;
}




.hidden_out {
    overflow: hidden;
    margin-left: -200%;
    transition: margin-left 0.5s linear;
}

.show_in {
    margin-left: 20px;
    transition: margin-left 0.5s linear;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.load {
    /* width: 100px;
	height: 100px;
	margin: 110px auto 0; */
    border: solid 2px #3c5ca6;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


.blink {
    border: none;
    animation: fadeinout .5s linear infinite running alternate;
}

.blinkbg {
    background-color: #0000;
    animation: fadeinoutbg .5s linear infinite running alternate;
}

.blinkopacity {
    opacity: 0;
    animation: fadeinoutopacity .5s linear infinite running alternate;
}

.blinkdropshadow {
    filter: drop-shadow(2px 2px 2px #ffffff);
    animation: fadeinoutopacity .5s linear infinite running alternate;
}

@keyframes fadeinout {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        box-shadow: #fff0 2px 3px 10px;
    }

    50% {
        box-shadow: rgba(255, 0, 0, 0.5) 2px 3px 10px;
    }

    100% {
        box-shadow: rgba(255, 0, 0, 1) 4px 6px 8px;
    }
}

@keyframes fadeinoutbg {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        background-color: #fff0;
    }

    50% {
        background-color: rgba(255, 0, 0, 0.5);
    }

    100% {
        background-color: rgba(255, 0, 0, 1);
    }
}

@keyframes fadeinoutopacity {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeinoutdropshadow {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        filter: drop-shadow(2px 3px 10px #fff0);
    }

    50% {
        filter: drop-shadow(2px 3px 10px rgba(255, 0, 0, 0.5));
    }

    100% {
        filter: drop-shadow(4px 6px 8px rgba(255, 0, 0, 1));
    }
}
.styles_notificationsIcon__1-yM0 {
    cursor: pointer;
    margin-right: 15px;
}

/* .notificationsPopupNotifications {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: auto;
    position: absolute;
    right: 20px;
    min-height: 150px;
    margin-top: 40px;
    
    background-color: #fff;
    
    border: 1px solid #c3c3c355;
    padding: 20px;
    border-radius: 5px;
    color: #fff;
    z-index: 800;
    min-width: 50vw;
    max-width: 50vw;
    box-shadow: #00000055 5px 5px 10px;
} */

.styles_notificationsContainer__1cCS4{
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    height: auto;
    overflow-y: auto;
}

.styles_notificationsRow__cxTtC {
    /* cursor: pointer; */
    display: flex;
    grid-gap: 8px;
    gap: 8px;
    /* flex-direction: row; */
    flex-direction: column;
    color: #3760c0;
    border: 1px solid #c3c3c355;
    box-shadow: 2px 2px 5px #0005;
    border-radius: 10px;
    padding: 10px;
    margin: 10px;
    
    overflow: hidden;
    transition: max-height 1s;
}

.styles_notificationsRowAvatar__3guCd{
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    border: 3px solid #3c68ce99;
}

.styles_notificationsContentModal__1XfP6 {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
    width: 60vw;
    height: 80vh;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.styles_notificationsContentModalOriginalMessage__3_tco {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}


.styles_notificationsContentModalComments__3R4yW {
    display: flex;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: row;
    background-color: #fff;
    color: #3c68ce;
    border-radius: 10px;
    padding: 20px;
}

.styles_CommentsFromMe__1IX-p {
    flex-direction: row-reverse;
}

.styles_notificationUserNameInMsg__1ADGG{
    justify-self: flex-end;
    /* margin-top: 10px; */
    /* margin-bottom: 10px; */
    opacity: 0.5;
}

.styles_notificationUserNameInMsg__1ADGG:hover{
    opacity: 1;
}
.styles_notificationUserNameInMsg__1ADGG:focus{
    opacity: 1;
}

/* .notificationsContentModalOriginalMessage div:nth-child(1) {
    height: 100px;
} */

/* .notificationsContentModalOriginalMessage div:nth-child(1) img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;

    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
} */

/* .notificationsContentModalOriginalMessage div:nth-child(2) {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    padding: 10px;
    height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
} */

/* ************************** */

.styles_notificationCommentAvatar__1XTDG {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid #3c68ce99;
    object-fit: cover;
    filter: drop-shadow(5px 5px 5px #222);
}

.styles_notificationCommentContent__1xMO5 {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: space-between;
    
    padding: 10px;
    min-height: 100px;
    width: calc(100% - 100px);
    box-shadow: 2px 2px 10px #0006;
    border-radius: 10px;
}


.styles_notificationsBtnNewPost__2xP3f{
    padding: 10px;
    display: flex;
    position: absolute;
    bottom: 30px;
    right: 30px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 2px 2px 10px #0006;
    cursor: pointer;
}

.styles_notificationsBtnNewPost__2xP3f span{
    margin: 0px;
}

/* ************************** */


.styles_notificationsTextbox__18frI {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 20vh;
    /* margin-top: 30px; */
    /* transition: margin-top 1s; */
    justify-content: center;
}

.styles_notificationsTextarea__1Fp2I {
    padding: 10px;
    border: 1px solid #0004;
    width: 91%;
    /* min-width: 91%; */
    max-height: 8vh;
    min-height: 8vh;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #0006;
    resize: none;
}

.styles_notificationsBtnSendComment__30FKP {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-left: 2%;
    height: 4vh;
    width: 4vh;
    border-radius: 4vh;
    border: 1px solid #0001;
    box-shadow: 2px 2px 10px #0006;
    cursor: pointer;
}

.styles_notificationsBtnSendComment__30FKP span {
    /* transform: rotate(-30deg); */
    margin: 0px;
    color: #fff;
    font-size: 3em;
}
.UploadComponentStyle_inputWrapper__17C3j{
  max-width: 140px;
  overflow: hidden;
}

.UploadComponentStyle_fileInput__1J8dx::-webkit-file-upload-button {
  visibility: hidden;
}

.UploadComponentStyle_fileInput__1J8dx::before {
  content: 'ANEXAR ARQUIVOS';
  display: inline-block;
  /* background: linear-gradient(top, #f9f9f9, #e3e3e3); */
  background-color: #3c68ce;
  border: none;
  border-radius: 5px;
  width: 140px;
  padding: 10px 8px;
  box-sizing: border-box;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  /* text-shadow: 1px 1px #fff; */
  color: #fff;
  font-weight: bold;
  text-align: center;
  /* font-size: 10pt; */
}

.UploadComponentStyle_fileInput__1J8dx:hover::before {
  border-color: black;
}

.UploadComponentStyle_fileInput__1J8dx:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}


.UploadComponentStyle_buttom__3eH0b {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;

  /*    TOP  RIGHT  BOTTOM  LEFT */
  padding: 3px 8px 3px 8px;

  background-color: #3c68ce;
  box-shadow: #0006 2px 2px 3px;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  cursor: pointer
}
.styles_containerRoot__1YRkc{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 100vh;
    padding-bottom: 30px;
}
.div_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-X: hidden;
}

.card {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: #0006 2px 3px 10px;
    width: 99%;
    max-width: 99%;
    min-height: 50px;
    align-items: center;
}

.form_group {
    display: flex;
    align-items: center;
}

.form_group_label {
    margin-right: 5px;
    font-size: 1.3em;
}

.form_group_content select {
    font-size: 1.3em;
    border: none;
    background-color: #fff;
}

.form_group_content input {
    width: 100px;
    font-size: 1.3em;
    border: none;
    background-color: #fff;
}

.pizza_chart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 99%;
}
.div_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-X: hidden;
}

.card {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: #0006 2px 3px 10px;
    width: 99%;
    max-width: 99%;
    min-height: 50px;
    align-items: center;
}

.form_group {
    display: flex;
    align-items: center;
}

.form_group_label {
    margin-right: 5px;
    font-size: 1.3em;
}

.form_group_content select {
    font-size: 1.3em;
    border: none;
    background-color: #fff;
}

.form_group_content input {
    width: 100px;
    font-size: 1.3em;
    border: none;
    background-color: #fff;
}

.pizza_chart {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 99%;
}
.styles_content__2SdsP{
    height: calc(100vh - 50px);
    max-height: calc(100vh - 50px);
    overflow: auto;
}
