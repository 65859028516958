.lineTimes{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lineTimes div:nth-child(1){
    width: 50px;
    height: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lineTimes div:nth-child(2){
    display: flex;
    flex-direction: row;
    align-items: center;
}

.lineTimes input{
    width: 160px;
}

.transitionHeight{
    transition: height 0.5s;
}