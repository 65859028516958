:root {
    --background-sidebar: #3c5ca6;
    --highlight-mouse-hover-sidebar: #324d8c;
}

.default_color {
    color: var(--background-sidebar);
    background-color: var(--background-sidebar);
}

.alerta {
    background-color: rgb(255, 171, 61);
    color: #253a6a;
}

.critico {
    background-color: rgb(255, 61, 61);
    color: #fff
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* max-width: calc(100dvw - 210px); */
    /* max-height: calc(100vh - 50px); */
    overflow-y: auto;
    overflow-X: hidden;

}

.outage-rows-container {
    display: flex;
    gap: 10;
    width: 100%;
    padding-bottom: 13;
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100vh - 50px - 170px)
}

.outage-details {
    display: flex;
    width: 100%;
    flex-direction: row;
    font-size: 1.3em;
}

.outage-details span {
    margin-left: 0px;
    margin-bottom: 5px;
    width: 50%;
    font-size: 1.2em;
}

.outage-details p {
    margin-top: 10px;
    font-size: 1.2em;
}

.outage-details p {
    font-size: 1em;
}

.outage-details b {
    font-size: 1em;
}

.outage-details-interactions {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.outage-details-interactions p:nth-child(2) {
    margin-top: 10px
}

.outage-details-interactions hr {
    margin-top: 10px;
    margin-bottom: 10px
}

.outage-rows-container {
    display: flex;
    gap: 10;
    width: 100%;
    padding-bottom: 13;
    flex-direction: column;
    overflow-y: auto;
    max-height: calc(100vh - 50px - 170px)
}

.row-outage-title {
    display: flex;
    flex-direction: row;
    width: 70%;
    align-items: center;
    gap: 10;
    align-self: center;
    font-size: 1.8em
}

.row-outage-title span {
    font-size: 1.3em
}

.row-outage-data {
    position: relative;
    display: flex;
    /* flex-direction: row; */
    width: 100%;
    flex-direction: column
}

.row-outage-data span {
    font-size: 1.4em;
}

.width_content {
    width: calc(100vw - 300px);
    max-width: calc(100vw - 300px);
}


.fixToCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}


.flex_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.flex_column {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-wrap: break-word;
}

.card {
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: #0006 2px 3px 10px;
    width: 99%;
    max-width: 99%;
    align-items: center;
}

.buttom {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;

    /*    TOP  RIGHT  BOTTOM  LEFT */
    padding: 3px 8px 3px 8px;

    background-color: var(--background-sidebar);
    box-shadow: #0006 2px 2px 3px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
    cursor: pointer
}

.buttom-disabled {
    display: flex;
    flex-direction: row;
    align-items: center;

    /*    TOP  RIGHT  BOTTOM  LEFT */
    padding: 3px 8px 3px 8px;

    background-color: #c3c3c3;
    box-shadow: #0006 2px 2px 3px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 5px;
}

.buttom:hover {
    background-color: var(--highlight-mouse-hover-sidebar)
}


.left_icon {
    margin-left: -3px;
    padding-top: 0px;
    margin-right: 5px;
}

.form {
    width: 50%;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}


.boxToHiddenEffect {
    height: auto;
    background: #000;
    color: #fff;
    cursor: pointer;
}

.hidden {
    height: 0px;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
}

.hidden.open {
    height: 200px;
    -webkit-transition: height 0.5s linear;
    -moz-transition: height 0.5s linear;
    -ms-transition: height 0.5s linear;
    -o-transition: height 0.5s linear;
    transition: height 0.5s linear;
}




.hidden_out {
    overflow: hidden;
    margin-left: -200%;
    -webkit-transition: margin-left 0.5s linear;
    -moz-transition: margin-left 0.5s linear;
    -ms-transition: margin-left 0.5s linear;
    -o-transition: margin-left 0.5s linear;
    transition: margin-left 0.5s linear;
}

.show_in {
    margin-left: 20px;
    -webkit-transition: margin-left 0.5s linear;
    -moz-transition: margin-left 0.5s linear;
    -ms-transition: margin-left 0.5s linear;
    -o-transition: margin-left 0.5s linear;
    transition: margin-left 0.5s linear;
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

.load {
    /* width: 100px;
	height: 100px;
	margin: 110px auto 0; */
    border: solid 2px #3c5ca6;
    border-radius: 50%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 1.0s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;

    transition: all 0.5s ease-in;
    animation-name: rotate;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}


.blink {
    border: none;
    -webkit-animation: fadeinout .5s linear infinite running alternate;
    animation: fadeinout .5s linear infinite running alternate;
}

.blinkbg {
    background-color: #0000;
    -webkit-animation: fadeinoutbg .5s linear infinite running alternate;
    animation: fadeinoutbg .5s linear infinite running alternate;
}

.blinkopacity {
    opacity: 0;
    -webkit-animation: fadeinoutopacity .5s linear infinite running alternate;
    animation: fadeinoutopacity .5s linear infinite running alternate;
}

.blinkdropshadow {
    -webkit-filter: drop-shadow(2px 2px 2px #ffffff);
    filter: drop-shadow(2px 2px 2px #ffffff);
    -webkit-animation: fadeinoutopacity .5s linear infinite running alternate;
    animation: fadeinoutopacity .5s linear infinite running alternate;
}

@-webkit-keyframes fadeinout {
    /* 0% { border: 0px solid #fff0; }
  50% { border: 1px solid orange; }
  100% { border: 3px solid red; } */

    0% {
        box-shadow: #fff0 2px 3px 10px;
    }

    50% {
        box-shadow: rgba(255, 0, 0, 0.5) 2px 3px 10px;
    }

    100% {
        box-shadow: rgba(255, 0, 0, 1) 4px 6px 8px;
    }
}

@keyframes fadeinout {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        box-shadow: #fff0 2px 3px 10px;
    }

    50% {
        box-shadow: rgba(255, 0, 0, 0.5) 2px 3px 10px;
    }

    100% {
        box-shadow: rgba(255, 0, 0, 1) 4px 6px 8px;
    }
}

@-webkit-keyframes fadeinoutbg {
    /* 0% { border: 0px solid #fff0; }
  50% { border: 1px solid orange; }
  100% { border: 3px solid red; } */

    0% {
        background-color: #fff0;
    }

    50% {
        background-color: rgba(255, 0, 0, 0.5);
    }

    100% {
        background-color: rgba(255, 0, 0, 1);
    }
}

@keyframes fadeinoutbg {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        background-color: #fff0;
    }

    50% {
        background-color: rgba(255, 0, 0, 0.5);
    }

    100% {
        background-color: rgba(255, 0, 0, 1);
    }
}

@-webkit-keyframes fadeinoutopacity {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeinoutopacity {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeinoutdropshadow {
    /* 0% { border: 0px solid #fff0; }
  50% { border: 1px solid orange; }
  100% { border: 3px solid red; } */

    0% {
        -webkit-filter: drop-shadow(2px 3px 10px #fff0 );
    }

    50% {
        -webkit-filter: drop-shadow(2px 3px 10px rgba(255, 0, 0, 0.5));
    }

    100% {
        -webkit-filter: drop-shadow(4px 6px 8px rgba(255, 0, 0, 1));
    }
}

@keyframes fadeinoutdropshadow {
    /* 0% { border: 0px solid #fff0; }
    50% { border: 1px solid orange; }
    100% { border: 3px solid red; } */

    0% {
        filter: drop-shadow(2px 3px 10px #fff0);
    }

    50% {
        filter: drop-shadow(2px 3px 10px rgba(255, 0, 0, 0.5));
    }

    100% {
        filter: drop-shadow(4px 6px 8px rgba(255, 0, 0, 1));
    }
}